import Image from 'next/image';
import Link from 'next/link';
import {InstagramSvg, XSvg} from '@delorand/ui/src/svg/logo';

const Footer = ({className}: {className?: string}) => {
  return (
    <div className={`w-full bg-black ${className ?? 'pt-20'} pb-7`}>
      <div className="mx-auto flex w-full max-w-screen-2xl flex-col items-center gap-5 px-6 pb-5 pt-5 text-white md:flex-row md:gap-0 md:pt-10">
        <div className="flex w-full items-center justify-between md:w-[35%] md:flex-col">
          <Image
            priority
            alt="delorand"
            src={'/delorandlogofullwhite.png'}
            width={130}
            height={50}
            className="opacity-80"
          />
          <div className="flex items-center gap-4 px-1 pr-2">
            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.instagram.com/delorandhq"
            >
              <InstagramSvg width={34} height={34} />
            </a>

            {/* <a
              target='_blank'
              rel='noreferrer'
              href='http://www.facebook.com/delorandhq'>
              <FacebookSvg width={28} height={28} />
            </a> */}

            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.twitter.com/delorandhq"
            >
              <XSvg width={34} height={34} />
            </a>
          </div>
        </div>

        <div className="mt-5 grid w-full grid-cols-2 gap-x-4 gap-y-3 whitespace-nowrap px-2 text-sm">
          <Link prefetch={false} className="" href="/help">
            Help Center
          </Link>
          <Link prefetch={false} href="/contact" className="">
            Contact us
          </Link>
          <Link prefetch={false} className="" href="/contact">
            Report a product
          </Link>
          <a
            className=""
            target="_blank"
            rel="noreferrer"
            href="http://www.instagram.com/delorandhq"
          >
            About us
          </a>
          <a className="" target="_blank" rel="noreferrer" href="/help">
            Delivery
          </a>
          <a
            className=""
            target="_blank"
            rel="noreferrer"
            href="http://www.instagram.com/delorandhq"
          >
            Other services
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://vendor.delorand.com"
            className=""
          >
            Become a vendor
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://api.whatsapp.com/sendphone=2349159114491text=Hello%20Delorand%2C%20I%20would%20love%20to..."
            className="text-[12px] md:text-sm"
          >
            {"We're hiring"}
          </a>
        </div>

        {/* <div className=''>
          <a className='flex items-center gap-1 md:gap-2'>
            <p className='text-sm md:text-xl'>Sign-up for Email</p>
            <EnvelopeIcon width={25} />
          </a>
        </div> */}
      </div>

      <div className="mt-4 flex justify-between px-7 text-sm">
        <Link prefetch={false} className="" href={'/privacy-policy'}>
          Privacy Policy
        </Link>
        <Link
          prefetch={false}
          className=""
          href={'/privacy-policy?active=cookies'}
        >
          Cookies
        </Link>
        <Link prefetch={false} className="" href={'/terms-and-conditions'}>
          Terms and Conditions
        </Link>
      </div>

      {/* end */}
      <div className="mt-5 flex justify-center gap-3 text-sm text-custom-white/70">
        <p>&#169; 2024 delorand</p>
      </div>
    </div>
  );
};

export default Footer;
